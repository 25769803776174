import React, { useCallback, useEffect } from 'react';
import './App.scss';

const wait = (time: number) => new Promise(resolve => setTimeout(resolve, time));

const validateEmailForm = (email: string): boolean => /^([-_.+0-9a-zA-Z])*@([-_.0-9a-zA-Z])*\.[a-zA-Z]{2,10}$/i.test(email);

function App() {

    const [lowResLoaded, setLowResLoaded] = React.useState(false);

    const [stage, setStage] = React.useState<'askForAnswer'|'askForQuestion'|'askForEmail'|'askForNotification'|'done'>('askForAnswer');

    const [originalMessage, setOriginalMessage] = React.useState<{question: string, originalMessageId: string} | undefined>(undefined);

    const [answer, setAnswer] = React.useState<string | undefined>(undefined);
    const [question, setQuestion] = React.useState<string | undefined>(undefined);
    const [email, setEmail] = React.useState<string | undefined>(undefined);
    const [notification, setNotification] = React.useState<boolean|undefined>(undefined);

    useEffect(()=>{
        const fetchData = async () => {
            const response = await fetch('https://api.today.zip/story', {method: 'GET'});
            const data = await response.json();
            setOriginalMessage(data);
        }
        fetchData();
    }, []);

    const sendData = useCallback(async () => {
        const data = {
            answer,
            question,
            email,
            notification,
            originalMessageId: originalMessage?.originalMessageId,
        };
        console.log(data);
        
        const response = await fetch('https://api.today.zip/story', {
            method: 'POST',
            body: JSON.stringify(data),
        });
        const result = await response.json();
        console.log(result);
        setStage('done');
    }, [answer, question, email, notification]);

    return (
        <div className="App">
            <div className={"RootBackground"}>
                <img id={'bg_low_res'} src={'/cosmos_low_resolution.png'} alt={"cosmos"} onLoad={()=>{ setLowResLoaded(true); }}/>
                <img id={'bg_high_res'} src={lowResLoaded?'/cosmos.png':''} style={{ opacity: lowResLoaded?1:0, }} alt={"cosmos"}/>
            </div>
            <div className='Body'>
                <div className={`Headline`}>
                    <div>
                        <span style={{fontWeight: 'bold'}}>{"Whenever you feel alone;"}</span><br/>
                        <span>{"remember: there's always someone ready to listen and answer your questions."}</span>
                    </div>
                </div>
                <div className={`Content ${stage === 'done' ? 'Fold':''}`}>
                    {(stage === 'askForAnswer' || answer !== undefined) 
                    && <div className={`Section ${stage !== 'askForAnswer' ? 'Fold':''}`}>
                        <span className={"SectionHeader"} onClick={()=>setStage('askForAnswer')}>{"Respond to someone's story:"}</span><br/>
                        <div className={"SectionBody"}> 
                            <span className={"Letter"}>{originalMessage?.question || "How was your day?"}</span><br/>
                            <textarea className={"InputArea"} placeholder={"tell them your opinion, it will be sent anonymously"} value={answer} onChange={(e)=> setAnswer(e.target.value)}/>
                            <button className={"Button"} disabled={!answer} onClick={()=>{ setStage('askForQuestion'); }}>{"Next"}</button>
                        </div>
                    </div>}
                    {(stage === 'askForQuestion' || question !== undefined) 
                    && <div className={`Section ${stage !== 'askForQuestion' ? 'Fold':''}`}>
                        <span className={"SectionHeader"} onClick={()=>setStage('askForQuestion')}>{"Thank you! and also:"}</span><br/>
                        <div className={"SectionBody"}> 
                            <span className={"Letter"}>{"What is on your mind? Good or bad, zip it! Somebody will respond it."}</span><br/>
                            <textarea className={"InputArea"} placeholder={"Yesterday I broke up, ..."} value={question} onChange={(e) => setQuestion(e.target.value)}/>
                            <button className={"Button"} disabled={!question} onClick={()=>{ setStage('askForEmail'); }}>{"Next"}</button>
                        </div>
                    </div>}
                    {(stage === 'askForEmail' || email !== undefined) 
                    && <div className={`Section ${stage !== 'askForEmail' ? 'Fold':''}`}>
                        <span className={"SectionHeader"} onClick={()=>setStage('askForEmail')}>{"Let someone respond your story:"}</span><br/>
                        <div className={"SectionBody"}> 
                            <input className={"InputArea"} type={"email"} placeholder={"email"} value={email} onChange={(e) => setEmail(e.target.value)}/>
                        </div>
                        <span className={"SectionHeader"} onClick={()=>setStage('askForNotification')}>{"Do you want to reveal your email?"}</span><br/>
                        <div className={"SectionBody FlexRow"}> 
                            <div
                                className={`Toggle ${notification === true && 'Active'}`}
                                onClick={()=>{
                                    setNotification(notification => notification === true  ? undefined : true );
                                }}
                            >{"Yes"}</div>
                            <div
                                className={`Toggle ${notification === false && 'Active'}`}
                                onClick={()=>{
                                    setNotification(notification => notification === false ? undefined : false);
                                }}
                            >{"No"}</div>
                        </div>
                        <button className={"Button"} disabled={!validateEmailForm(email || '') || notification === undefined} onClick={()=>{ sendData(); }}>{"Okay"}</button>
                    </div>}
                </div>
                <div className={`Content ${stage !== 'done' ? 'Fold':''}`}>
                    <div className={`Section`}>
                        <span className={"SectionHeader"}>{"Thank you!"}</span><br/>
                        <div className={"SectionBody"}> 
                            <span className={"Letter"}>{`Your message has been sent.\nWe'll let you know when someone responds.`}</span>
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <a href="mailto:today.zip.domain@gmail.com">{"today.zip.domain@gmail.com"}</a>
            </footer>
        </div>
    );
}

export default App;
